import React, { useState } from "react";
import { FaBars, FaWindowClose } from "react-icons/fa";
import tw from "twin.macro";
import { useNavItems } from "../hooks/useNavItems";
import Link from "./Link";
import SocialLinks from "./SocialLinks";

const NavMenu: React.FC<{}> = (): React.ReactElement => {
  const [open, setOpen] = useState(false);

  const navItems = useNavItems();

  const toggleOpen = () => {
    if (window && window.innerWidth > 768) return;

    if (open) {
      setOpen(false);
      document.body.style.overflow = "auto";
    } else {
      setOpen(true);
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <>
      <button onClick={toggleOpen} css={[tw`p-3 md:hidden`]}>
        <FaBars css={[tw`text-3xl`]} />
      </button>
      <nav
        css={[
          tw`flex gap-8 fixed top-0 left-0 right-0 bottom-0 bg-primary bg-opacity-[97%] flex-col p-8 text-2xl justify-between`,
          tw`md:(static bg-transparent flex-row p-0 text-base)`,
          tw`opacity-0 pointer-events-none md:(opacity-100 pointer-events-auto)`,
          open && tw`opacity-100 pointer-events-auto`,
        ]}
      >
        <button onClick={toggleOpen}>
          <FaWindowClose css={[tw`absolute right-8 top-8 md:(hidden)`]} />
        </button>
        {navItems.map((link) => {
          const active = window?.location?.href?.includes(link.url);
          return (
            <Link
              to={link.url}
              css={[
                tw`border-b-4 border-transparent hover:(border-white) focus:(border-white) transition`,
                active && tw`border-white`,
              ]}
              onClick={() => toggleOpen()}
            >
              {link.label}
            </Link>
          );
        })}
        <SocialLinks />
      </nav>
    </>
  );
};

export default NavMenu;
