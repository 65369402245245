import React from "react";
import tw from "twin.macro";
import { useNavItems } from "../hooks/useNavItems";
import { useSocialLinks } from "../hooks/useSocialLinks";
import Container from "./Container";
import Link from "./Link";
import NavMenu from "./NavMenu";
import SocialLinks from "./SocialLinks";

const Header: React.FC<{}> = (): React.ReactElement => {
  return (
    <header css={[tw`text-white absolute w-full top-0 text-lg z-50`]}>
      <Container css={[tw`flex justify-between items-center py-8`]}>
        <Link
          to="/"
          css={[
            tw`text-xl uppercase font-[900] tracking-wider font-display relative`,
          ]}
        >
          <img
            src="/images/signature.svg"
            css={[
              tw`opacity-25 absolute max-w-none`,
              "width: 140%; left: -10%; top: -100%;",
            ]}
          />
          Rachael T. Panik
        </Link>
        <NavMenu />
      </Container>
    </header>
  );
};

export default Header;
