exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-teaching-tsx": () => import("./../../../src/pages/teaching.tsx" /* webpackChunkName: "component---src-pages-teaching-tsx" */)
}

