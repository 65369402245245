import React from "react";
import { useSocialLinks } from "../hooks/useSocialLinks";
import Link from "./Link";
import {
  FaInstagram,
  FaLinkedin,
  FaTwitter, FaThreads
} from "react-icons/fa6";
import tw from "twin.macro";

type Link = {
  url: string;
  label: string;
};

const icons: any = {
  twitter: FaTwitter,
  linkedin: FaLinkedin,
  instagram: FaInstagram,
  threads: FaThreads,
};

const SocialLinks: React.FC<{}> = ({ ...remainingProps }) => {
  const socialLinks: Link[] = useSocialLinks();

  return (
    <div
      {...remainingProps}
      css={[tw`flex items-center justify-between gap-3`]}
    >
      {socialLinks.map((link: Link) => {
        const Icon = icons[link.label];
        return (
          <Link
            to={link.url}
            css={[tw`hover:(opacity-75) focus:(opacity-75) transition`]}
          >
            <Icon />
          </Link>
        );
      })}
    </div>
  );
};

export default SocialLinks;
