import { graphql, useStaticQuery } from "gatsby";

export const useSocialLinks = () => {
  const socialLinks = useStaticQuery(graphql`
    {
      sanityAbout {
        socialLinks {
          label: socialChannel
          url
        }
      }
    }
  `);
  return socialLinks.sanityAbout.socialLinks;
};
