import React from "react";
import tw from "twin.macro";

const Footer: React.FC<{}> = (): React.ReactElement => {
  return (
    <footer css={[tw`text-center pt-16 pb-8 text-gray-100`]}>
      &copy; {new Date().getFullYear()}, Rachael T. Panik. All Rights Reserved.
    </footer>
  );
};

export default Footer;
