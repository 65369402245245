import React from "react";
import { Link } from "gatsby";
import tw from "twin.macro";

type CustomLinkProps = {
  href?: string;
  to: string;
  onClick?: (arg0: any) => any;
  children: React.ReactNode;
};

const CustomLink: React.FC<CustomLinkProps> = ({
  to,
  href,
  children,
  ...remainingProps
}) => {
  let customTo = href || to;

  if (customTo.startsWith("http")) {
    return (
      <a href={customTo} {...remainingProps} target="_blank">
        {children}
      </a>
    );
  } else {
    return (
      <Link to={to} {...remainingProps}>
        {children}
      </Link>
    );
  }
};

export default CustomLink;
