import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import tw, { GlobalStyles } from "twin.macro";
import { HeadFC } from "gatsby";

type LayoutProps = { children: React.ReactNode };

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div css={[tw`font-sans text-gray-300`]}>
      <GlobalStyles />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
