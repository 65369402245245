export const useNavItems = () => {
  return [
    { url: "/about", label: "About Me" },
    { url: "https://www.overleaf.com/read/dkypyhmvkqfv", label: "CV" },
    { url: "/publications", label: "Publications" },
    { url: "/media", label: "Media" },
    { url: "/teaching", label: "Teaching" },
    { url: "/contact", label: "Contact" },
  ];
};
